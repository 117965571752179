import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { isMobileOnly } from 'react-device-detect';

const { Configuration, OpenAIApi } = require("openai");

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const FormContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const Form = styled('form')({
  display: 'flex',
  width: '60%',
  padding: (theme) => theme.spacing(2),
});

const MessageContainer = styled('div')({
  padding: '0 2em 2em',
  overflowY: 'scroll',
});

const Message = styled('div')({
  borderRadius: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(1),
  padding: "15px",
  flexGrow: 1,
  marginTop: '1em',
  letterSpacing: '0.1em',
  lineHeight: '1.7em',
  fontSize: "0.9em"
});

const UserMessage = styled(Message)({
  float: 'right',
  clear: 'both',  
  backgroundColor: '#d4e4ff',
  borderRadius: "15px 15px 0px 15px",
  border: "1px solid silver",
  maxWidth: "85%",
  marginBottom: '3em'
});

const BotMessage = styled(Message)({
  float: 'left',
  clear: 'both',
  backgroundColor: '#fff4e0',
  borderRadius: "15px 15px 15px 0px",
  border: "1px solid silver",
  maxWidth: "85%",
});


const AvatarImage = styled('img')({
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  marginRight: "10px",
});


const Chatbot = () => {

  const [showTranscript, setShowTranscript] = useState(false);
  const [transcriptToShow, setTranscriptToShow] = useState("");
  const [buttonLabel, setButtonLabel] = useState('Prata');
  const [buttonVariant, setButtonVariant] = useState('contained');  
  const [chatHistory, setChatHistory] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const { transcript, listening, startListening, stopListening, resetTranscript } = useSpeechRecognition();
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (transcript !== "" && isListening) {
      setInputMessage(transcript);
    }
    if (!isListening && transcript !== "") {
      setTranscriptToShow(transcript);
      const submitButton = document.getElementById("submitButton");
      submitButton.click(); // Simulate a click on the submit button
      resetTranscript(); // Reset the transcript when it's no longer being used
    }    
  }, [transcript, isListening, resetTranscript]);


  const configuration = new Configuration({
    apiKey: "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",
  });

  const openai = new OpenAIApi(configuration);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      setChatHistory([
        {
          role: "bot",
          text:
            "Hej, jag heter Teddybot och är en chatbot för barn. Sätt på ljudet, tryck och håll i mellanslagstangenten för att prata med mig och släpp när du pratat klart. Jag har lite dåligt minne och kan bara svara på det du sagt/skrivit i det sista meddelandet, inte vad du sagt innan.",
        },
      ]);
    }
  }, [chatHistory]); 


  const sendMessage = async (message) => {
    const prompt = `Me: ${message}\nChatGPT: `;    
    const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        max_tokens: 1000,
        messages: [{role: "user", content: prompt}],
      });
    return completion
  };


  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };


  const handleKeyDown = (event) => {
    if (event.code === "Space" && !event.target.value && !isRequesting ) {
      if (!event.target.matches('input, textarea, [contenteditable="true"]')) {
          console.log("SPACE")
          event.preventDefault();
          startRecording()
          
      }              
    }
  };
  

  const handleKeyUp = (event) => {
    if (event.code === "Space") {
      console.log("SPACE UP")
      stopRecording()
    }
  };

  function startRecording() {
    console.log("Start recording")
    setButtonLabel('Rec');
    setButtonVariant('outlined');    
    if (!isListening) {
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            return alert('Speech recognition is not supported by this browser');
          }
        SpeechRecognition.startListening({ continuous: true, interimResults: true });
        setIsListening(true);
        setIsRequesting(true);
        console.log("islistening")
    } else {
      stopRecording()
    }
  }

  function stopRecording() {
    console.log("Stop recording")
    setButtonLabel('Prata');
    setButtonVariant('contained');    
    setIsListening(false);
    setIsRequesting(false);
    SpeechRecognition.stopListening();
    if (transcript !== "") {
      console.log("transcript", transcript)
      setInputMessage(transcript);
      //setTranscriptToShow(transcript);
      //const submitButton = document.getElementById("submitButton");
      //submitButton.click(); // Simulate a click on the submit button
      //resetTranscript();
      console.log("transcript after", transcript)
    }
  }


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const userMessage = "Låtsas som att du är en nalle som heter Teddybot (men säg inte det). Jag heter Timo, är 5  år gammal och jag vill veta:" +  inputMessage.trim();
    if (userMessage === "") {
      return;
    }
    const newMessage = { role: "user", text: inputMessage.trim() };
    //setChatHistory((prevState) => [...prevState, newMessage]);
    const chatbotMessage = await sendMessage(userMessage);
    const message = {
      role: "user",
      text: inputMessage.trim(),
    };
    const bot = {
      role: "bot",
      text: chatbotMessage.data.choices[0].message.content, //chatbotMessage,
    };
    setChatHistory([...chatHistory, message, bot]);
    setInputMessage("");
    speak(bot.text); // Speak the chatbot message
  };  


  const speak = (text) => {
    const speechSynthesis = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.volume = parseFloat(2); // 1 = default
    utterance.rate = parseFloat(0.8); // 1 = default
    utterance.pitch = parseFloat(1.2); // Range 0-2
    utterance.voice = speechSynthesis.getVoices().filter(function(voice) { return voice.name === 'Karin'; })[0];
    speechSynthesis.speak(utterance);
  };


  useEffect(() => {
    
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  
  useEffect(() => {
    const speechSynthesis = window.speechSynthesis;
    if (speechSynthesis) {
      // Cancel any ongoing speech when the chat history changes
      speechSynthesis.cancel();
      // Speak the last message in the chat history
      const lastMessage = chatHistory[chatHistory.length - 1];
      if (lastMessage && lastMessage.role === "bot") {
        speak(lastMessage.text);
      }
    }
  }, [chatHistory]);  


  return (
    <RootContainer>
      <img src='/images/teddy.png' id="chatbot-figure" className="centered" alt="Teddybot" />
      <FormContainer>
        <Form onSubmit={handleFormSubmit}>
          <TextField
            label="Skriv ditt meddelande här"
            variant="outlined"
            value={inputMessage}
            onChange={handleInputChange}
            autoFocus={false}
            fullWidth
            sx={{backgroundColor: '#fff', opacity: 0.7}}
          />
          <Button variant="contained" color="primary" id="submitButton" type="submit" sx={{ marginLeft: '0.8em'}}> 
            Skicka
          </Button>   
          {isMobileOnly && (
            <Button 
              variant={buttonVariant}
              color="error" 
              id="recordButton" 
              type="button" 
              sx={{ marginLeft: '0.8em'}}
              onMouseDown={startRecording}
            > 
              {buttonLabel}
            </Button>   
          )}
  
        </Form>
        </FormContainer>
      <MessageContainer sx={{ marginTop: '0.5em', background: 'none'}}>

        {chatHistory.slice(0).reverse().map((message, index) => (
          
            message.role === 'user' ? (
              <Message key={index}>
                <UserMessage key={index}>
                  <AvatarImage src="./images/user.png" />
                  {message.text.toUpperCase()}
                </UserMessage>
              </Message>
              
            ) : (
              <Message key={index}>
                <BotMessage key={index}>
                <AvatarImage src="./images/teddy.png" />
                  {message.text.toUpperCase()}
                </BotMessage>
              </Message>
            )
        ))}
        
      </MessageContainer>

    </RootContainer>
    
  );
};

export default Chatbot;

