import './App.css';
import Chatbot from './Chatbot';


function App() {
  return (
    <div className="App">      
      <Chatbot />
    </div>
  );
}

export default App;